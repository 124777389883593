import { logout } from "../../appRedux/actions";


export const startSessionTimeout = (dispatch, timeout) => {
    let timer;

    const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(logout());
        }, timeout);
    };

    resetTimer();

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);

    return () => {
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        clearTimeout(timer);
    };
};