import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router'; // Import routerMiddleware
import thunk from 'redux-thunk';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers'; // Your root reducer
import { createBrowserHistory } from 'history';

// Create browser history
export const history = createBrowserHistory();

// Create router middleware for syncing history with redux
const routeMiddleware = routerMiddleware(history);

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// Set up middleware stack
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

// Configure the store
export default function configureStore(preloadedState = {}) {
  const store = createStore(
    createRootReducer(history), // Pass the history object to the rootReducer
    preloadedState,
    compose(
      applyMiddleware(...middlewares), // Apply the middleware here
    )
  );

  // Run saga middleware
  sagaMiddleware.run(rootSaga);

  return store;
}
