import React from 'react';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
} from '@ant-design/icons';

const FooterSection = () => {
  const footerStyle = {
    backgroundColor: '#ABDFF1', // Oceanic blue background
    color: '#fff',
    padding: '10px 20px',
    display: 'flex',
    bottom: 0,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto', // Ensures the footer stays at the bottom
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%', // Now takes the full width of the screen
    padding: '0 40px', // Optional padding for content spacing
    marginBottom: '20px',
  };

  const columnStyle = {
    flex: '1',
    minWidth: '250px', // Ensures columns adjust responsively
    padding: '20px',
    textAlign: 'center',
    color:'black',
  };

  const iconStyle = {
    fontSize: '24px',
    color: 'black',
    margin: '0 10px',
    cursor: 'pointer',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    display: 'block',
    marginBottom: '10px',
  };

  const inputStyle = {
    padding: '10px',
    marginBottom: '10px',
    border: 'none',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '300px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#fff',
    color: '#001529',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        {/* Follow Us Column */}
        <div style={columnStyle}>
          <h3>Follow Us</h3>
          <div>
            <FacebookOutlined style={iconStyle} />
            <TwitterOutlined style={iconStyle} />
            <InstagramOutlined style={iconStyle} />
            {/* <LinkedInOutlined style={iconStyle} /> */}
            <GithubOutlined style={iconStyle} />
          </div>
        </div>

        {/* About Us Column */}
        <div style={columnStyle}>
          <h3>About Us</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>

        {/* Newsletter Column */}
        <div style={columnStyle}>
          <h3>Newsletter</h3>
          <input
            type="email"
            placeholder="Subscribe to our newsletter"
            style={inputStyle}
          />
          <button style={buttonStyle}>Subscribe</button>
        </div>

        {/* Contact Us Column */}
        <div style={columnStyle}>
          <h3>Contact Us</h3>
          <a href="mailto:info@bongohotels.com" style={{linkStyle, color:'black'}}>
            <MailOutlined style={{ marginRight: '8px',color:'black' }} /> info@bongohotels.com
          </a>
          <a href="tel:+123456789" style={{linkStyle,color:'black'}}>
            <PhoneOutlined style={{ marginRight: '8px' }} /> +123 456 789
          </a>
          <p>
            <HomeOutlined style={{ marginRight: '8px' }} />
            123 Bongo Street, Dar es Salaam, Tanzania
          </p>
        </div>
      </div>
      <p style={{color:'black'}}>&copy; {new Date().getFullYear()} Bongo Hotels. All rights reserved.</p>
    </footer>
  );
};

export default FooterSection;
