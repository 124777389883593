import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GITHUB_USER_FAILED,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_TWITTER_USER_FAILED,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_FACEBOOK_USER_FAILED,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_FAILED,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILED,
} from "constants/ActionTypes";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
} from "../../constants/ActionTypes";

// !actions for session and refresh token
export const loginSuccess = (token, refreshToken) => ({
  type: LOGIN_SUCCESS,
  payload: { token, refreshToken },
});

export const logout = () => ({
  type: LOGOUT,
});

export const refreshToken = (token) => ({
  type: REFRESH_TOKEN,
  payload: { token },
});

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

// signin with provider
export const userGoogleSignIn = () => {
  return async (dispatch) => {
    try {
      // const response = await fetch('https://bongohotels.ewallet-dev.com/login/oauth2/code/google', { method: 'GET' });
      window.location.href =
      "https://bongohotels.ewallet-dev.com/oauth2/authorization/google";
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: SHOW_MESSAGE,
        payload: error.message,
      });
    }
  };
};

export const userFacebookSignIn = () => {
  return async (dispatch) => {
    try {
      window.location.href =
        "https://bongohotels.ewallet-dev.com/oauth2/authorization/facebook";
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        payload: error.message,
      });
    }
  };
};
// for signup

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};

export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignUpFailed = () => {
  return {
    type: SIGNUP_USER_FAILED,
  };
};

// export const userGoogleSignIn = () => {
//   return {
//     type: SIGNIN_GOOGLE_USER
//   };
// };

export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};

export const userGoogleSignInFailed = () => {
  return {
    type: SIGNIN_GOOGLE_USER_FAILED,
  };
};

// export const userFacebookSignIn = () => {
//   return {
//     type: SIGNIN_FACEBOOK_USER
//   };
// };

export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};

export const userFacebookSignInFailed = () => {
  return {
    type: SIGNIN_FACEBOOK_USER_FAILED,
  };
};

export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};

export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};

export const userGithubSignInFailed = () => {
  return {
    type: SIGNIN_GITHUB_USER_FAILED,
  };
};

export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};

export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};

export const userTwitterSignInFailed = () => {
  return {
    type: SIGNIN_TWITTER_USER_FAILED,
  };
};
