import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../../firebase/firebase";

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  userSignUp,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";
import axios_ from "../../util/axios";

const loginRequest = async (email, password) => {
  const payload = {
    email,
    password,
  };

  console.log(payload);
  return await axios_
    .post("/auth/login", payload)
    .then((authUser) => {
      console.log(authUser);
      return authUser.data;
    })
    .catch((error) => {
      console.log(error.response.data);
      return error.response.data;
    });
};
// const signOutRequest = async () =>
//   await  auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(loginRequest, email, password);
    console.log(signInUser);
    if (signInUser) {
      localStorage.setItem("user_id", signInUser.user.id);
      localStorage.setItem("token", signInUser.token);
      localStorage.setItem("user", JSON.stringify(signInUser.user));
      // localStorage.setItem("user_id", 1)
      // localStorage.setItem("token", "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJTYWlkIiwicm9sZXMiOlsiL2x1a3UtdG9wdXAiLCIvZGFzaGJvYXJkIiwiL3Byb2ZpbGUiLCIvbWVyY2hhbnRzIl0sImV4cCI6MTcyNTM0NDQyMSwiaWF0IjoxNzI1Mjg0NDIxfQ.YA_3O03pUh93hP9o0HHmyiAI-cA5kCv-Q5wgXGvfshk9aWULNaq3ai1O7fjZCv-Z_HCC4ffysFbY0Jp1HlK9Ug")
      // localStorage.setItem("user", {"roles":[{"id":8,"createdAt":"2024-08-30T06:16:34.000+00:00","updatedAt":"2024-08-30T06:16:34.000+00:00","name":"/dashboard","description":"MERCHANT Dashboard","type":"USER","status":"Active"},{"id":6,"createdAt":"2024-08-30T06:16:34.000+00:00","updatedAt":"2024-08-30T06:16:34.000+00:00","name":"/dashboard","description":"Admin Dashboard","type":"ADMIN","status":"Active"},{"id":5,"createdAt":"2024-08-30T06:16:34.000+00:00","updatedAt":"2024-08-30T06:16:34.000+00:00","name":"/luku-topup","description":"Top-up Luku","type":"USER","status":"Active"},{"id":7,"createdAt":"2024-08-30T06:16:34.000+00:00","updatedAt":"2024-08-30T06:16:34.000+00:00","name":"/merchants","description":"Admin Manage Merchants","type":"ADMIN","status":"Active"},{"id":10,"createdAt":"2024-08-30T06:16:34.000+00:00","updatedAt":"2024-08-30T06:16:34.000+00:00","name":"/profile","description":"Merchant Profile","type":"USER","status":"Active"}],"token":"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJTYWlkIiwicm9sZXMiOlsiL2x1a3UtdG9wdXAiLCIvZGFzaGJvYXJkIiwiL3Byb2ZpbGUiLCIvbWVyY2hhbnRzIl0sImV4cCI6MTcyNTM0NDQyMSwiaWF0IjoxNzI1Mjg0NDIxfQ.YA_3O03pUh93hP9o0HHmyiAI-cA5kCv-Q5wgXGvfshk9aWULNaq3ai1O7fjZCv-Z_HCC4ffysFbY0Jp1HlK9Ug","user":{"id":6,"createdAt":"2024-03-14T10:40:05.000+00:00","updatedAt":"2024-03-14T10:40:05.000+00:00","name":"Said","username":"Said","email":"email@xyz.com","msisdn":"0658989823","roleName":"ADMIN","lastLogin":"2024-09-02T13:40:21.297+00:00","isActive":1,"dateCreated":"2024-03-14T10:40:05.000+00:00","status":"Active"}})
      yield put(userSignInSuccess(signInUser));
    } else {
      yield put(showAuthMessage(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = null;
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      yield put(signOutUser);
      window.location.reload();
    } else {
      yield put(showAuthMessage(signOutUser.message));
      localStorage.removeItem("user_id");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload();
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
// request for signup

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => {
      console.log("sigup", authUser);
      return authUser;
    })
    .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => {
      console.log("signup provider", authUser)
      return authUser
    })
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

// function with authentication from firebase

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      console.log("signupfail", signUpUser.message);
      yield put(showAuthMessage(signUpUser.message));
    } else {
      console.log("sigupsucc", signUpUser);
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      console.log(signUpUser.message)
      yield put(showAuthMessage(signUpUser.message));
    } else {
      console.log("signup", signUpUser)
      localStorage.setItem("user_id", signUpUser.user.uid);
      localStorage.setItem("user", signUpUser.user);
      localStorage.setItem("token", signUpUser?.token);
      yield put(userGoogleSignInSuccess(signUpUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// authentication with social media account
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithGithub),
    fork(signInWithTwitter),
  ]);
}
