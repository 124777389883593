import React, {memo} from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";

const UserProfile = ({ authUser }) => {
  // console.log(authUser)
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => logout()}>Logout</li>
    </ul>
  );

  const logout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="gx-flex-column gx-align-items-center gx-mb-2 gx-avatar-row">
      <Popover
        placement="bottom"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={"https://randomuser.me/api/portraits/men/32.jpg"}
          className="gx-size-40 gx-pointer gx-mb-2"
          alt="User Avatar"
        />
        <span className="gx-avatar-name gx-text-center">
          {/* {JSON.parse(localStorage.getItem("user")).fullName} */}
          {/* <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/> */}
        </span>
      </Popover>
    </div>
  );
};

export default memo(UserProfile);