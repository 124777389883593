import React, { useState } from "react";
import { Input, Button, Dropdown, Menu, DatePicker } from "antd";
import { SearchOutlined, CalendarOutlined, DownOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const SearchBar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);

  const handleSearch = () => {
    onSearch({ searchQuery, dateRange, adults, children, rooms });
  };

  const guestMenu = (
    <Menu>
      <Menu.Item>
        <div>
          <span>Adults</span>
          <div>
            <Button icon="-" onClick={() => setAdults(Math.max(1, adults - 1))} />
            <span>{adults}</span>
            <Button icon="+" onClick={() => setAdults(adults + 1)} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div>
          <span>Children</span>
          <div>
            <Button icon="-" onClick={() => setChildren(Math.max(0, children - 1))} />
            <span>{children}</span>
            <Button icon="+" onClick={() => setChildren(children + 1)} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div>
          <span>Rooms</span>
          <div>
            <Button icon="-" onClick={() => setRooms(Math.max(1, rooms - 1))} />
            <span>{rooms}</span>
            <Button icon="+" onClick={() => setRooms(rooms + 1)} />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: "flex", alignItems: "center", padding: "10px", borderRadius: "20px", backgroundColor: "#FFFFFF", border: "2px solid #FFC72C" }}>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Hotel name"
        style={{ width: "200px", height: "50px", marginRight: "-2px" }}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <RangePicker
        suffixIcon={<CalendarOutlined />}
        placeholder={["Check-in date", "Check-out date"]}
        style={{ width: "250px", height: "50px", marginRight: "-2px" }}
        onChange={(dates) => setDateRange(dates)}
      />
      <Dropdown overlay={guestMenu} trigger={["click"]}>
        <Button style={{ width: "350px", height: "50px", marginRight: "50px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {`${adults} adults • ${children} children • ${rooms} room${rooms > 1 ? "s" : ""}`}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Button
        type="primary"
        style={{ backgroundColor: "#005EB8", border: "none", color: "#FFFFFF", width: "120px", height: "50px" }}
        onClick={handleSearch}
      >
        Search
      </Button>
    </div>
  );
};

export default SearchBar;
