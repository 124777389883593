import axios from "axios";

import configureStore from "../appRedux/store";
import { logout, refreshToken } from "../appRedux/actions";

const BASE_URL = "https://bongohotels.ewallet-dev.com/api/v1"


// const axios_ = axios.create({
//   baseURL: `https://bongohotels.ewallet-dev.com/api/v1`
//   // baseURL: `http://localhost:8096/api/v1`
// });

// axios_.interceptors.request.use((config) => {

//   const token = localStorage.getItem("token")
//   config.params = config.params || {};
//   config.headers['Content-type'] = `application/json`
//   config.headers['Authorization'] = `Bearer ${token}`
//   return config;
// });

// export default axios_;


const axios_ = axios.create({
  baseURL: BASE_URL,
});

axios_.interceptors.request.use(

  (config) => {
    const token = localStorage.getItem("token");
    config.params = config.params || {};
    config.headers = config.headers || {};
    config.headers["Content-type"] = "application/json";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokens = localStorage.getItem("refreshToken");
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`, { refreshTokens });
        const { token } = response.data;
        localStorage.setItem("token", token);
        configureStore.dispatch(refreshToken(token));
        if (originalRequest.headers) {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
        }
        return axios_(originalRequest);
      } catch (refreshError) {
        configureStore.dispatch(logout());
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axios_;
