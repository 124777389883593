import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  Input,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Tooltip,
  Pagination,
  Image,
  Tabs,
  Dropdown,
  Menu,
  Switch,
} from "antd";
import {
  EnvironmentOutlined,
  CompassOutlined,
  PictureOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
  SearchOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios_ from "../util/axios";

const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const BrowsingHotelPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);

  // State for hotels and loading state
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for enhanced search filters
  const [searchQuery, setSearchQuery] = useState(""); // Search query for location or hotel name
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [pets, setPets] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const hotelsPerPage = 8;

  // Fetch hotels data from API
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios_.get("/dashboard"); // Replace with your API endpoint
        setHotels(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hotels:", error);
        setLoading(false);
      }
    };

    fetchHotels();
  }, []);

  // Filter hotels based on enhanced search filters
  const filteredHotels = hotels.filter((hotel) =>
    hotel.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedHotels = filteredHotels.slice(
    (currentPage - 1) * hotelsPerPage,
    currentPage * hotelsPerPage
  );

  const showModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalVisible(true);
  };

  const showDetailsModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsDetailsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsDetailsModalVisible(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const guestMenu = (
    <Menu style={{ padding: "10px", width: "250px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Adults</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setAdults((prev) => Math.max(0, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{adults}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setAdults((prev) => prev + 1)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Children</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setChildren((prev) => Math.max(0, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{children}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setChildren((prev) => prev + 1)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Rooms</span>
        <div>
          <Button
            icon={<MinusOutlined />}
            onClick={() => setRooms((prev) => Math.max(1, prev - 1))}
          />
          <span style={{ margin: "0 10px" }}>{rooms}</span>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setRooms((prev) => prev + 1)}
          />
        </div>
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <span>Traveling with pets?</span>
        <Switch checked={pets} onChange={(checked) => setPets(checked)} />
      </div>
      <Button
        type="primary"
        style={{ width: "100%" }}
        onClick={() => console.log("Done clicked!")}
      >
        Done
      </Button>
    </Menu>
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            border: "2px solid #FFC72C",
          }}
        >
          <Input
            prefix={<SearchOutlined />}
            placeholder="Hotel name"
            style={{ width: "200px", height: "50px", marginRight: "-2px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <RangePicker
            suffixIcon={<CalendarOutlined />}
            placeholder={["Check-in date", "Check-out date"]}
            style={{ width: "250px", height: "50px", marginRight: "-2px" }}
            onChange={(dates) => setDateRange(dates)}
          />
          <div style={{ paddingTop: "1rem", display: "flex" }}>
            <Dropdown overlay={guestMenu} trigger={["click"]}>
              <Button
                style={{
                  width: "350px",
                  height: "50px",
                  marginRight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {`${adults} adults • ${children} children • ${rooms} room${
                  rooms > 1 ? "s" : ""
                }`}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              style={{
                backgroundColor: "#005EB8",
                border: "none",
                color: "#FFFFFF",
                width: "120px",
                height: "50px",
              }}
              onClick={() => console.log("Search clicked!")}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div
        style={{ maxWidth: "100%", paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Title level={2} className="text-center mb-8">
          Our Featured Hotels
        </Title>

        <Row gutter={[16, 16]}>
          {displayedHotels.map((hotel) => (
            <Col xs={24} md={12} key={hotel.id}>
              <Card style={{ width: "100%" }}>
                <Row>
                  <Col>
                    {/* Photo Gallery */}
                    {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {hotel.images?.map((image) => (
          <Image
            key={image.id}
            src={image.imageUrl}
            alt={image.name}
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        ))}
      </div> */}
                  </Col>

                  <Col xs={24} md={12}>
                    <Title level={3}>{hotel.name}</Title>
                    <Paragraph>
                      <EnvironmentOutlined /> {hotel.address}
                    </Paragraph>
                    <Paragraph>{hotel.description}</Paragraph>
                    <Paragraph>
                      Registered as: {hotel.businessLicence}
                    </Paragraph>
                    <Paragraph>
                      Maximum Number of Visitors : {hotel.maximumHotelCapacity}
                    </Paragraph>
                    <Paragraph>
                      You can visit Online through :
                      <a
                        href={`https://${hotel.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hotel.website}
                      </a>
                    </Paragraph>
                    <Paragraph>Property Type: {hotel.propertyType}</Paragraph>
                    <Paragraph>Operation Status: {hotel.status}</Paragraph>
                    <Paragraph>
                      <strong>
                        From Tsh.{hotel.currency}
                        {hotel.price_per_night} per night ({hotel.discount})
                      </strong>
                    </Paragraph>
                    <Paragraph>
                      {/* <Link to={`/bookHotel/${hotel.id}`} style={{ color: 'blue' }}>
            Info about this Hotel
          </Link> */}
                    </Paragraph>

                    <Button
                      icon={<PictureOutlined />}
                      onClick={() => showDetailsModal(hotel)}
                    >
                      View Other Details
                    </Button>
                    <Space>
                      <Button type="primary" onClick={() => showModal(hotel)}>
                        Book Now
                      </Button>
                      <Tooltip title="View on Google Maps">
                        <Button
                          icon={<CompassOutlined />}
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${hotel.location}`,
                              "_blank"
                            )
                          }
                        >
                          Map
                        </Button>
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>

        <div className="flex justify-center mt-8">
          <Pagination
            current={currentPage}
            total={filteredHotels.length}
            pageSize={hotelsPerPage}
            onChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        title={`Book ${selectedHotel?.name}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {/* Booking form modal */}
      </Modal>

      <Modal
        title={selectedHotel?.name}
        visible={isDetailsModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Tabs defaultActiveKey="1">
          {/* Rooms Tab */}
          <Tabs.TabPane tab="Rooms" key="1">
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              {selectedHotel?.rooms?.map((room) => (
                <Card
                  key={room.id}
                  style={{ flex: "1 1 30%", marginBottom: 16 }} // Flex properties to adjust layout
                >
                  <Title level={4}>
                    Room {room.name} (Room Number: {room.roomNumber})
                  </Title>
                  <p>Status: {room.status}</p>
                  <p>Stay Policy: {room.stayPolicy}</p>
                  <p>
                    <strong>
                      Price: {selectedHotel?.currency}
                      {room.price} per night
                    </strong>
                  </p>
                </Card>
              ))}
            </div>
          </Tabs.TabPane>

          {/* Description Tab */}
          <Tabs.TabPane tab="Description" key="2">
            <p>{selectedHotel?.description}</p>
          </Tabs.TabPane>

          {/* Other Details Tab */}
          <Tabs.TabPane tab="Other Details" key="3">
            <p>
              {selectedHotel?.location} | {selectedHotel?.rating} |{" "}
              {selectedHotel?.distance_from_city_center}
            </p>
          </Tabs.TabPane>

          {/* Amenities Tab */}

          <Tabs.TabPane tab="Amenities" key="4">
            <ul>
              {selectedHotel?.amenities?.map((amenity, index) => (
                <li key={index}>{amenity.amenityName}</li>
              ))}
            </ul>
          </Tabs.TabPane>

          {/* Policies Tab */}
          <Tabs.TabPane tab="Policies" key="5">
            <p>
              <strong>Cancellation/Refund Policy:</strong>{" "}
              {selectedHotel?.refundPolicyAmount}
            </p>
            <p>
              <strong>Check-in:</strong> {selectedHotel?.check_in}
            </p>
            <p>
              <strong>Check-out:</strong> {selectedHotel?.check_out}
            </p>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default BrowsingHotelPage;
