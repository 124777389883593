import React, { useEffect, useState } from "react";
import { Descriptions, Card, Spin,Row, Col,Image,Button } from "antd";
import  axios_  from "../util/axios";
import {
  EnvironmentOutlined,PictureOutlined
} from "@ant-design/icons";
import { Typography } from 'antd';


const {  Title,Paragraph } = Typography;
const HotelDetailsPage = () => {
  const [hotelDetails, setHotelDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios_.get("/dashboard");
        console.log(response.data)
        setHotelDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
{ hotelDetails && hotelDetails.map((hotel) => {
  return(
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12} >
<Card style={{ margin: "20px", width:"100%" }}>
  <Row>
  <Col xs={24} md={12}>
<Image src={hotel.image} alt={hotel.name} style={{width:"100%"}} />
  </Col>
  <Col xs={24} md={12}>
<Title level={3}>{hotel.name}</Title>
<Paragraph><EnvironmentOutlined /> {hotel.address}</Paragraph>
<Paragraph>{hotel.description}</Paragraph>
<Paragraph>Registered as: {hotel.businessLicence}</Paragraph>
<Paragraph>Maximum Number of Visitors : {hotel.maximumHotelCapacity}</Paragraph>
<Paragraph>You can visit Online through  :
<a href={`https://${hotel.website}`} target="_blank" rel="noopener noreferrer">
            {hotel.website}
          </a>
</Paragraph>
<Paragraph>Property Type: {hotel.propertyType}</Paragraph>
<Paragraph>Operation Status: {hotel.status}</Paragraph>
<Button icon={<PictureOutlined />} onClick={() => (hotel)}>
                      View Other Details
                    </Button>
  </Col>
  </Row>
    </Card>
    </Col>
    </Row>
  )
    })
}
    </div>


  );
};

export default HotelDetailsPage;
