import React from "react";
import ClientContainer from "./ClientContainer";
import ClientHero from "../components/ClientHero/index";
import ClientFooter from "../components/ClientFooter/index";
import SearchBar from "../components/SearchBar/searchBar";
import BrowseHotel from "../containers/BrowseHotel";


const Home = () => {


    return (
        <ClientContainer>
            <ClientHero />
            <BrowseHotel />
            {/* <ClientFooter /> */}

        </ClientContainer>
    );
};

export default Home;
