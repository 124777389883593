import React, { useState, useEffect } from 'react';
import { Layout, Input, Card, Row, Col, Button, DatePicker, Select } from 'antd';
import { SearchOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const UserPortal = () => {
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    // Fetch hotels from your API
    // This is a placeholder and should be replaced with your actual API call
    const fetchHotels = async () => {
      try {
        const response = await fetch('/api/hotels');
        const data = await response.json();
        setHotels(data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };

    fetchHotels();
  }, []);

  return (
    <Content className="user-portal">
      <div className="search-section">
        <h1>Find deals on hotels, homes, and much more...</h1>
        <div className="search-form">
          <Search
            placeholder="Where are you going?"
            prefix={<SearchOutlined />}
            className="destination-input"
          />
          <RangePicker className="date-picker" />
          <Select defaultValue="2 adults" className="guests-select">
            <Option value="1 adult">1 adult</Option>
            <Option value="2 adults">2 adults</Option>
            <Option value="2 adults, 1 child">2 adults, 1 child</Option>
          </Select>
          <Button type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </div>
      </div>

      <div className="hotel-list">
        <h2>Popular hotels</h2>
        <Row gutter={[16, 16]}>
          {hotels.map((hotel) => (
            <Col xs={24} sm={12} md={8} lg={6} key={hotel.id}>
              <Card
                hoverable
                cover={<img alt={hotel.name} src={hotel.image} />}
                actions={[
                  <Button type="primary" href={`/booking/${hotel.id}`}>
                    Book Now
                  </Button>,
                ]}
              >
                <Card.Meta title={hotel.name} description={hotel.description} />
                <div className="hotel-price">From ${hotel.price} per night</div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Content>
  );
};

export default UserPortal;