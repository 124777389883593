import React from "react";
import { Layout } from "antd";
import "antd/dist/antd.css";
// import ClientH from "../components/clientHeader/index";
import ClientFooter from "../components/ClientFooter/index";
import ClientHome from "../components/clientHeader/index";

const { Content } = Layout;

const ClientContainer = ({ children }) => {
  return (
    <Layout style={{ height: "100vh",bottom: 0, width: "100vw", overflow: "hidden" }}>
      <ClientHome />
      <Content
        style={{ flex: 1, overflowY: "auto", overflowX: "hidden", width: "100%", }}
      >
        {children}
        <ClientFooter />
      </Content>
    </Layout>

  //   <Layout style={{ height: "100vh",bottom: 0, width: "100vw", overflow: "hidden" }}>
  //     <ClientHeader />
  //   <div  style={{ flex: 1, overflowY: "auto", overflowX: "hidden", width: "100%", }}>
  //     {/* Main content */}
  //     <div>
  //       <h1>Main Content Goes Here</h1>
  //       {children}
  //       {/* Other content */}
  //     </div>
  //   <ClientFooter />
  //   </div>

  //   {/* Footer will be at the bottom */}
  // </Layout>
  );
};

export default ClientContainer;
