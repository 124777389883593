import React, { Component, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css"
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import BrowseHotel from "../BrowseHotel";
import BookingHotel from "../BookHotel";
import apiPage from "../apiPage"

import { setInitUrl } from "../../appRedux/actions";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import Home from "../Home";
import { startSessionTimeout } from "../../util/Services/startSessionTimeout";
import { auth } from "../../firebase/firebase";
import UserPortal from "../UserPortal/UserPortal";
import MainLayout from "../UserPortal/MainLayout";
import UnrestrictedLayout from "../UserPortal/UnrestructedLayout";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;

// const withAuth = (WrappedComponent) => {
//   return (props) => {
//     const dispatch = useDispatch();
//     const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
//     const location = useLocation();
//     const history = useHistory();

//     useEffect(() => {
//       if (!isAuthenticated) {
//         history.push('/signin', { from: location });
//       } else {
//         const cleanup = startSessionTimeout(dispatch, 30 * 60 * 1000); // 30 minutes
//         return cleanup;
//       }
//     }, [isAuthenticated, dispatch, history, location]);

//     return isAuthenticated ? <WrappedComponent {...props} /> : null;
//   };
// };

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

  const { authUser, initURL } = useSelector(({ auth }) => auth);

  console.log("Logged in user: ", authUser)
  console.log("Logged in initUrl: ", initURL)

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => { 
    if (location.pathname === '/') {
      if (authUser === null || initURL === '') {
        history.push('/home');
      } else if (authUser && initURL === '/') {
        history.push('/dashboard');
      } else if (initURL) {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];
  const unrestrictedRoutes = [
    { path: '/home', component: Home },
    { path: '/signin', component: SignIn },
    { path: '/signup', component: SignUp },
    { path: '/apiPage', component: apiPage },
    { path: '/booking/:hotelId', component: BookingHotel },
    { path: '/user-portal', component: UserPortal },
  ];

  // const AuthenticatedMainApp = withAuth(MainApp)
  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
     
     <Switch>
        <Route exact path='/home' component={Home} />
        <Route exact path='/signin' component={SignIn} />
        <Route exact path='/signup' component={SignUp} />
        {/* <Route exact path='/apiPage' component={apiPage} /> */}
        <Route exact path='/booking/:hotelId' component={BookingHotel} />
        {/* <Route exact path='/user-portal' component={UserPortal} /> */}
          {/* {unrestrictedRoutes.map(({ path, component: Component }) => (
              <Route
                key={path}
                exact
                path={path}
                render={(props) => (
                  <UnrestrictedLayout>
                    <Component {...props} />
                  </UnrestrictedLayout>
                )}
              />
            ))} */}
        <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
      </Switch>
     
    </IntlProvider>
  </ConfigProvider>
  )
};

export default memo(App);
