import React from 'react';
import { Affix, Button, Menu } from 'antd';
import { Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';

const { Header } = Layout;

const ClientHome = () => {
  const { Item } = Menu;
  const history = useHistory();

  const headerStyle = {
    backgroundColor: '#ABDFF1', // Oceanic blue background
    padding: '10px 20px',
    width: '100%',
    height: '100px',
    position: 'fixed',
    zIndex: 1000,
  };

  const logoStyle = {
    height: '40px',
    marginRight: '20px',
  };

  const menuStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'white',
    flexGrow: 1,
  };

  const menuItemStyle = {
    color: 'black',
    padding: '0 15px',
    fontFamily: 'Arial, sans-serif', // Changed font to Arial
    fontSize: '24px', // Increased font size
    fontWeight: 'bold',
    position: 'relative',
    transition: 'font-style 0.3s ease',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
    position: 'relative',
  };

  const hoverEffect = {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: 'white',
    bottom: '-6px',
    left: '0',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  };

  const menuItemHoverStyle = {
    ...menuItemStyle,
    fontStyle: 'italic',
    '&:hover::after': {
      opacity: 1,
    },
  };

  return (
    <Affix offsetTop={0}>
      <Header style={headerStyle}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', color: 'black', fontFamily: 'Arial', fontSize: '28px' }}>
          <Link to="/home" style={linkStyle}>
            <img
              style={logoStyle}
              src="/assets/images/logo.png"
              alt="logo"
            />
            BONGO HOTELS
          </Link>

          <Menu
            mode="horizontal"
            defaultSelectedKeys={['']}
            overflowedIndicator={<MenuOutlined style={{ color: '#fff' }} />}
            style={{ background: 'transparent', border: 'none', flexGrow: 1, justifyContent: 'flex-end' }}
          >
            {/* <Item key="apiPage" style={menuItemStyle}>
              <a style={{hoverEffect,linkStyle}} href="/apiPage" className="text-style">apiPage</a>
            </Item> */}
            {/* <Item key="browseHotel" style={menuItemStyle}>
              <a style={{hoverEffect,linkStyle}} href="/browseHotel" className="text-style">Browse Hotel</a>
            </Item> */}
            {/* <Item key="booking" style={menuItemStyle}>
              <a style={{linkStyle,hoverEffect}} href="/booking" className="text-style">Booking</a>
            </Item> */}
            {/* <Item key="about" style={menuItemStyle}>
              <a
                style={{ hoverEffect }}
                href="footer"
                onClick={() => document.getElementById('client-footer').scrollIntoView({ behavior: 'smooth' })}
                className="text-style"
              >
                About
              </a>
            </Item> */}
          </Menu>

          <Button type="primary" className="gx-mb-0" onClick={() => history.push('/signIn')}>Sign IN</Button>
          <Button type="primary" className="gx-mb-0" onClick={() => history.push('/signUp')}>Register</Button>
        </div>
      </Header>
    </Affix>
  );
};

export default ClientHome;
