import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BookHotel = () => {
  const { hotelId } = useParams(); // Retrieve hotelId from URL parameters

  const [hotelData, setHotelData] = useState(null);

  useEffect(() => {
    // Simulating a data fetch based on hotelId
    const fetchHotelData = async () => {
      try {
        // Here you would replace with an actual API call
        // For example, a call like axios.get(`/api/hotels/${hotelId}`)
        const data = {
          id: hotelId,
          name: 'Sample Hotel',
          location: 'Sample Location',
        };

        setHotelData(data); // Set hotel data
      } catch (error) {
        console.error('Error fetching hotel data:', error);
      }
    };

    fetchHotelData();
  }, [hotelId]);

  return (
    <div>
      <h1>Hello World!</h1>
      {hotelData ? (
        <div>
          <p>Hotel ID: {hotelData.id}</p>
          <p>Hotel Name: {hotelData.name}</p>
          <p>Hotel Location: {hotelData.location}</p>
        </div>
      ) : (
        <p>Loading hotel data...</p>
      )}
    </div>
  );
};

export default BookHotel;
